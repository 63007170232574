<template>
  <base-modal-ex v-if="isActive"
    :active.sync="isActive"
    :animation-in="animationIn"
    :animation-out="animationOut"
    :animation-duration="animationDuration"
    :can-cancel="false"
    :min-width="30"
    has-modal-card>
    <header class="modal-card-head">
      <div class="modal-card-title"
        :class="{ 'is-flex-items-center' : showIcon}">
        <span v-if="showIcon"
          class="icon is-medium"
          :class="`has-text-${iconType}`"
          style="margin-right: 0.5rem">
          <i class="mdi mdi-36px"
            :class="`${iconName}`" />
        </span>
        <span>Print Options</span>
      </div>
    </header>
    <section class="modal-card-body">
      <div class="field">
        <span>Select report</span>
      </div>
      <div class="field">
        <div class="control">
          <input class="is-checkradio"
            id="quoteAnalysis"
            type="radio"
            name="quoteAnalysis"
            :value="'QuoteAnalysis'"
            v-model="report">
          <label for="quoteAnalysis">Quote Analysis</label>
        </div>
      </div>
      <!-- <div class="field">
        <div class="control">
          <input
            class="is-checkradio"
            id="quoteAnalysisExcel"
            type="radio"
            name="quoteAnalysisExcel"
            :value="'QuoteAnalysisExcel'"
            v-model="report">
          <label for="quoteAnalysisExcel">Quote Analysis Additional Columns</label>
        </div>
      </div> -->
      <div class="field">
        <div class="control">
          <input class="is-checkradio"
            :id="reportFormatTypes.excel"
            type="radio"
            :name="reportFormatTypes.excel"
            :value="reportFormatTypes.excel"
            v-model="report">
          <label :for="reportFormatTypes.excel">Quote Analysis export to Excel</label>
        </div>
      </div>
      <div class="field">
        <input class="is-checkradio"
          id="strikeRate"
          type="radio"
          name="strikeRate"
          :value="'StrikeRate'"
          v-model="report">
        <label for="strikeRate">Strike Rate</label>
      </div>
    </section>
    <footer class="modal-card-foot"
      :style="`justify-content: flex-${buttonLocation}`">
      <button class="button is-success tooltip"
        @click="exportReport"
        data-tooltip="Export to Excel"
        :disabled="report && report != 'excel'">
        <span class="icon">
          <i class="mdi mdi-file-export mdi-18px" />
        </span>
        <span>Export</span>
      </button>
      <button class="button is-success tooltip"
        @click="print"
        data-tooltip="Print report"
        :disabled="report && report == 'excel'">
        <span class="icon">
          <i class="mdi mdi-printer mdi-18px" />
        </span>
        <span>Print</span>
      </button>
      <button class="button tooltip"
        @click="close()"
        data-tooltip="Close">Close</button>
    </footer>
  </base-modal-ex>
</template>

<script>
import { BaseModalEx } from '@/components/BulmaModal'
import { ReportFormatTypes } from '@/enums'
// import PrintPreviewService from './PrintPreviewService'
// import SmsSend from './SmsSend'

export default {
  name: 'QuoteListPrintModal',
  components: {
    BaseModalEx
    // SmsMessage
  },
  props: {
    value: null,
    active: {
      type: Boolean,
      default: false
    },
    animationIn: {
      type: String,
      default: 'zoomIn'
    },
    animationOut: {
      type: String,
      default: 'zoomOut'
    },
    animationDuration: {
      type: [String, Number],
      default: 0.3
    },
    buttonLocation: {
      type: String,
      default: 'end' // valid values 'start' & 'end'
    },
    showIcon: {
      type: Boolean,
      default: true
    },
    iconType: {
      type: String,
      default: 'primary'
    },
    iconName: {
      type: String,
      default: 'mdi-printer'
    }
  },
  data() {
    return {
      isActive: false,
      report: ''
    }
  },
  computed: {
    reportFormatTypes() {
      return ReportFormatTypes
    }
  },
  mounted() {
    this.isActive = this.active || false
  },
  methods: {
    print() {
      this.isActive = false
      this.$emit('update:active', false)
      this.$emit('print', this.report)
    },
    exportReport() {
      this.isActive = false
      this.$emit('update:active', false)
      this.$emit('export', this.report)
    },
    close() {
      this.isActive = false
      this.$emit('update:active', false)
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
.is-flex-items-center {
  display: flex;
  align-items: center;
}
</style>
